import React from 'react';
import a from './../../Table.module.scss'
import {BrowserRouter as Router, Switch, Route, NavLink} from "react-router-dom";
import Stage0 from "../Stage/Stage0";
import Stage1 from "../Stage/Stage1";
import Stage2 from "../Stage/Stage2";
import Stage3 from "../Stage/Stage3";
import Stage4 from "../Stage/Stage4";
import Stage5 from "../Stage/Stage5";

class StoreComponents extends React.Component {
	constructor (props) {
		super( props );
		this.state = {
			BabyStatus: [],
			Children: [],
			Status: [],
			Levels: [],
			Lang: [],
			Schedule: [],
			Helps: [],
			Animals: [],
			StartOptions: [],
			Source: [],
			Stages: [],
			parserTagsName: [],
			parserHelpsName: [],
			isLoading: true,
			ChangeTags: [],
			FilterBabySisters: [],
			Matching: [],
		};
	}

	fetchData (url, state) {
		this.setState( {
			isLoading: true,
		} );

		fetch( url )
			.then( (response) => {
				if (!response.ok) {
					throw Error( response.statusText );
				}

				return response;
			} )
			.then( (response) => response.json() )
			.then( (e) => {
				switch (state) {
					case "Matching":
						this.setState( {
							Matching: e,
						} );
						break;
					case "BabyStatus":
						this.setState( {
							BabyStatus: e,
						} );
						break;
					case "Children":
						this.setState( {
							Children: e,
						} );
						break;
					case "Status":
						this.setState( {
							Status: e,
						} );
						break;
					case "Levels":
						this.setState( {
							Levels: e,
						} );
						break;
					case "Lang":
						this.setState( {
							Lang: e,
						} );
						break;
					case "Schedule":
						this.setState( {
							Schedule: e,
						} );
						break;
					case "Helps":
						this.setState( {
							Helps: e,
						} );
						break;
					case "Animals":
						this.setState( {
							Animals: e,
						} );
						break;
					case "StartOptions":
						this.setState( {
							StartOptions: e,
						} );
						break;
					case "Source":
						this.setState( {
							Source: e,
						} );
						break;
					case "Stages":
						this.setState( {
							Stages: e,
						} );
						break;
					default:
						console.log( '' );
				}
			} )
			.catch( () => this.setState( {hasErrored: true} ) );
	}

	dispatch (action) {

	}

	componentDidMount () {
		const fetchDataArray = [
			{url: "http://admin.barnakademinstockholm.se/json/filter", state: "Matching"},
			{url: "http://admin.barnakademinstockholm.se/json/sister-status", state: "BabyStatus"},
			{url: "http://admin.barnakademinstockholm.se/json/children", state: "Children"},
			{url: "http://admin.barnakademinstockholm.se/json/status", state: "Status"},
			{url: "http://admin.barnakademinstockholm.se/json/lang", state: "Lang"},
			{url: "http://admin.barnakademinstockholm.se/json/levels", state: "Levels"},
			{url: "http://admin.barnakademinstockholm.se/json/schedule", state: "Schedule"},
			{url: "http://admin.barnakademinstockholm.se/json/helps", state: "Helps"},
			{url: "http://admin.barnakademinstockholm.se/json/animals", state: "Animals"},
			{url: "http://admin.barnakademinstockholm.se/json/statusoptions", state: "StartOptions"},
			{url: "http://admin.barnakademinstockholm.se/json/source", state: "Source"},
			{url: "http://admin.barnakademinstockholm.se/json/stages", state: "Stages"},
		];

		fetchDataArray.map( f =>
			this.fetchData( f.url, f.state )
		)
	}

	render () {
		const {Matching} = this.state;
		return (
			<div className={a.App}>
				<button onClick={() => this.dispatch( {type: "result"} )}>RESULT</button>

				<Router>
					<div>
						<div className={a.tHeader}>
							<NavLink exact activeClassName={a.active} to="/stage0">
								<div className={`${a.itemLink}`}>
									<span>Stage 0</span>
									<span>0 clients</span>
								</div>
							</NavLink>

							<NavLink exact activeClassName={a.active} to="/">
								<div className={`${a.itemLink}`}>
									<span>Stage 1</span>
									<span>{Matching.length - 1} clients</span>
								</div>
							</NavLink>

							<NavLink activeClassName={a.active} to="/stage2">
								<div className={`${a.itemLink}`}>
									<span>Stage 2</span>
									<span>4 clients</span>
								</div>
							</NavLink>

							<NavLink activeClassName={a.active} to="/stage3">
								<div className={`${a.itemLink}`}>
									<span>Stage 3</span>
									<span>3 clients</span>
								</div>
							</NavLink>

							<NavLink activeClassName={a.active} to="/stage4">
								<div className={`${a.itemLink}`}>
									<span>Stage 4</span>
									<span>2 clients</span>
								</div>
							</NavLink>

							<NavLink activeClassName={a.active} to="/stage5">
								<div className={`${a.itemLink}`}>
									<span>Stage 5</span>
									<span>1 clients</span>
								</div>
							</NavLink>
						</div>
						<div className={a.sort}>
							<div className={a.itemSort}>
								<input type="checkbox" value=""/>
							</div>
							<div className={a.itemSort}>Client</div>
							<div className={a.itemSort}>Babysitters</div>
							<div className={a.itemSort}>Language(s)</div>
							<div className={a.itemSort}>Location</div>
							<div className={a.itemSort}>Extra demands</div>
							<div className={a.itemSort}>Status</div>
							<div className={a.itemSort}>Notes</div>
							<div className={a.itemSort}>Actions</div>
						</div>
						<Switch>
							<Route path="/stage0">
								<Stage0/>
							</Route>

							<Route path="/stage2">
								<Stage2/>
							</Route>
							<Route path="/stage3">
								<Stage3/>
							</Route>
							<Route path="/stage4">
								<Stage4/>
							</Route>
							<Route path="/stage5">
								<Stage5/>
							</Route>
							<Route path="/">
								<Stage1
									store={this.state}
								/>
							</Route>
						</Switch>
					</div>
				</Router>
			</div>
		)
	}
}

export default StoreComponents;