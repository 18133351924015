import React from "react";

let renderEntireTree = () => {};

const store_ = {
  BabyStatus: [],
  Children: [],
  Status: [],
  Levels: [],
  Lang: [],
  Schedule: [],
  Helps: [],
  Animals: [],
  StartOptions: [],
  Source: [],
  Stages: [],
  parserTagsName: [],
  parserHelpsName: [],
  isLoading: true,
  ChangeTags: [],
  FilterBabySisters: [],
  Matching: [],

  getState() {
    return store_;
  },
  fetchData(url, state) {
    store_.isLoading = true;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }

        return response;
      })
      .then((response) => response.json())
      .then((e) => {
        switch (state) {
          case "Matching":
            store_.Matching = e;
            break;
          case "BabyStatus":
            store_.BabyStatus = e;
            break;
          case "Children":
            store_.Children = e;
            break;
          case "Status":
            store_.Status = e;
            break;
          case "Levels":
            store_.Levels = e;
            break;
          case "Lang":
            store_.Lang = e;
            break;
          case "Schedule":
            store_.Schedule = e;
            break;
          case "Helps":
            store_.Helps = e;
            break;
          case "Animals":
            store_.Animals = e;
            break;
          case "StartOptions":
            store_.StartOptions = e;
            break;
          case "Source":
            store_.Source = e;
            break;
          case "Stages":
            store_.Stages = e;
            break;
          default:
            return store_;
        }
      })
      .catch(() => this.setState({ hasErrored: true }));
  },
  fetchDataArray: [
    {url: "http://admin.barnakademinstockholm.se/json/filter", state: "Matching"},
    {url: "http://admin.barnakademinstockholm.se/json/sister-status", state: "BabyStatus"},
    {url: "http://admin.barnakademinstockholm.se/json/children", state: "Children" },
    {url: "http://admin.barnakademinstockholm.se/json/status", state: "Status" },
    {url: "http://admin.barnakademinstockholm.se/json/lang", state: "Lang" },
    {url: "http://admin.barnakademinstockholm.se/json/levels", state: "Levels" },
    {url: "http://admin.barnakademinstockholm.se/json/schedule", state: "Schedule" },
    {url: "http://admin.barnakademinstockholm.se/json/helps", state: "Helps" },
    {url: "http://admin.barnakademinstockholm.se/json/animals", state: "Animals" },
    {url: "http://admin.barnakademinstockholm.se/json/statusoptions", state: "StartOptions"},
    {url: "http://admin.barnakademinstockholm.se/json/source", state: "Source" },
    {url: "http://admin.barnakademinstockholm.se/json/stages", state: "Stages" },
  ],
  dispatch(action) {
    if (action.type === "result") {
      console.log(store_);
    }
  },
  GetAge(dateString) {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();

    let m = today.getMonth() - birthDate.getMonth();
    let d = today.getDay() - birthDate.getDay();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age === 0) {
      m = 12 + m;
      if (d < 0 || (d === 0 && today.getDate() < birthDate.getDate())) {
        m--;
      }
    }
    return age ? age + " y." + m + " m." : null;
  },
};

store_.fetchDataArray.map((f) => store_.fetchData(f.url, f.state));

export default store_;
window.store = store_;
