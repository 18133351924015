import React, { Component } from "react";
import s from "../Stage/Stage.module.scss";
import b from "../Babysitters/Babysitters.module.scss";
import "../../../public/img/time.svg";

const FilterBabysitters = (props) => {
  // debugger
  const StatusColor = (event) => {
    let color = props.store.Status.filter((s) => s.id === event) || undefined,
      colorVal = "";

    if (color.length > 0) {
      colorVal = color[0].color;
    }
    return colorVal;
  };

  const StatusName = (event) => {
    let name = props.store.Status.filter((s) => s.id === event),
      nameValue = "";

    if (name.length > 0) {
      nameValue = name[0].name;
    }
    return nameValue;
  };

  return (
    <div>
      <div>
        <div>
          <div className={s.babysittersItem}>
            <div>
              <input type="checkbox" />
            </div>

            <div className={s.babysittersPhoto}>
              {props.Sitter.babysitter_photo !== null ? (
                <img
                  style={{
                    border: `2px solid ${StatusColor(+props.Sitter.babysitter_status)}`,
                  }}
                  src={`http://admin.barnakademinstockholm.se/${props.Sitter.babysitter_photo}`}
                  alt=""
                />
              ) : (
                <img src="http://admin.barnakademinstockholm.se/img/avatar.jpg" alt="" />
              )}
              <div className={b.icons}>
                <div data-rate={props.Sitter.babysitter_rate}>
                  {props.Sitter.babysitter_rate}
                </div>
              </div>

              <div>
                {props.store.Status.map((m) =>
                  m.id === +props.Sitter.babysitter_status ? (
                    <div
                      className={`${s.babysitterStatus} ${+props.Sitter.babysitter_status === 1 ? b.borderColor : null}`}
                      style={{
                        background: `${StatusColor(+props.Sitter.babysitter_status)}`,
                      }}
                    >
                      <span
                        className={`${ props.Sitter.babysitter_status > 1
                            ? `${b.colorWhite}`
                            : `${b.colorBlack}`}  `

                        }
                      >

                        {StatusName(+props.Sitter.babysitter_status)}
                      </span>
                    </div>
                  ) : null
                )}
              </div>

              <div className={s.babysittersNameHover} style={{ display: "none" }}>
                <div className={s.babysitterCard}>
                  <span>BabysitterCard:</span>
                  {props.Sitter.babysitter_name}
                </div>

                <div>
                  {props.Sitter.babysitter_photo !== null ? (
                    <img
                      style={{
                        width: "70px",
                        height: "70px",
                        margin: "10px 0 0",
                        border: `2px solid ${StatusColor(
                          +props.Sitter.babysitter_status
                        )}`,
                      }}
                      src={`http://admin.barnakademinstockholm.se/${props.Sitter.babysitter_photo}`}
                      alt=""
                    />
                  ) : (
                    <img
                      style={{
                        width: "70px",
                        height: "70px",
                        margin: "10px 0 0",
                        border: `2px solid ${StatusColor(
                          +props.Sitter.babysitter_status
                        )}`,
                      }}
                      src="http://admin.barnakademinstockholm.se/img/avatar.jpg"
                      alt=""
                    />
                  )}

                  {props.store.Status.map((m) =>
                    m.id === +props.Sitter.babysitter_status ? (
                      <div
                        className={s.status}
                        style={{
                          background: `${StatusColor(+props.Sitter.babysitter_status)}`,
                        }}
                      >

                        <span
                          className={
                            props.Sitter.babysitter_status > 1
                              ? `${b.colorWhite}`
                              : `${b.colorBlack}`
                          }
                        >
                          {StatusName(+props.Sitter.babysitter_status)}
                        </span>
                      </div>
                    ) : null
                  )}
                </div>

                <div className={s.addedWrap}>
                  <span className={s.hoverAdded}>
                    Added:
                    <span className={s.addedColor}>{props.Sitter.babysitter_added}</span>
                  </span>
                  {props.Sitter.babysitter_availability !== "" ? (
                    <span className={s.hoverAdded}>
                      Availability:
                      <span className={s.availabilityColor}>
                        {props.Sitter.babysitter_availability}
                      </span>
                    </span>
                  ) : null}

                  {props.Sitter.babysitter_quit !== null ? (
                    <span className={s.hoverAdded}>
                      Quit:
                      <span className={s.quitColor}>{props.Sitter.babysitter_quit}</span>
                    </span>
                  ) : null}
                </div>

                <div>
                  {props.Sitter.language.map((l) => (
                    <>
                      <span className={s.hoverLangFont}>{l.lang_name}</span>
                      {props.store.Levels.map((le) =>
                        le.id === l.level_id ? (
                          <span
                            className={s.hoverLangFont}
                            style={{
                              background: le.color,
                              width: "2rem",
                              textAlign: "center",
                            }}
                          >
                            {le.name}
                          </span>
                        ) : null
                      )}
                    </>
                  ))}
                </div>

                <div className={s.hoverWrapPhone}>
                  <span className={s.hoverPhone}>
                    <img className={s.hoverImg} src="/img/phone.svg" alt="" />
                    {props.Sitter.babysitter_phone}
                  </span>
                  <span className={s.hoverEmail}>
                    <img className={s.hoverImg} src="/img/email.svg" alt="" />
                    {props.Sitter.babysitter_email}
                  </span>
                </div>

                <div></div>

                <div className={s.babysittersDemands}>
                  {props.Sitter.tags !== undefined
                    ? props.Sitter.tags.map((t) => (
                        <span className={s.demands}>{t.name}</span>
                      ))
                    : null}
                </div>
              </div>
            </div>

            <div className={s.babysittersInfo}>
              <div className={s.babysittersName}>{props.Sitter.babysitter_name}</div>
              <div className={s.babysittersInfoFont}>{props.Sitter.babysitter_email}</div>
              <div className={s.babysittersInfoFont}>{props.Sitter.babysitter_phone}</div>

              <div>
                <a
                  href={props.Sitter.babysitter_presentation}
                  className={`${s.btnPresentation} ${
                    props.Sitter.babysitter_presentation === "" ? s.hide : null
                  }`}
                  target={`_blank`}
                >
                  Presentation
                </a>

                <a
                  href={props.Sitter.babysitter_video}
                  className={`${s.btnVideo} ${
                    props.Sitter.babysitter_video === null ? s.hide : null
                  }
                                  ${props.Sitter.babysitter_video === "" ? s.hide : null}
                                  `}
                  target={`_blank`}
                >
                  Video
                </a>
              </div>

              <div className={s.babysittersMeet}>
                <p>23-03-21- met</p>
                <img src="/img/time.svg" alt="" />
              </div>
            </div>

            <div>
              <span className={s.babysittersFont}>
                <>
                  <>
                    <>
                      {props.Sitter.language.map((l) => (
                        <div>
                          <span>{l.lang_name}</span>
                          <>
                            {props.store.Levels.map((le) =>
                              le.id === l.level_id ? (
                                <div
                                  style={{
                                    background: le.color,
                                    width: "2rem",
                                    textAlign: "center",
                                    'display': 'block'
                                  }}
                                >
                                  {le.name}
                                </div>
                              ) : null
                            )}
                          </>
                        </div>
                      ))}
                    </>
                  </>
                </>
              </span>
            </div>

            <div className={s.babysittersLocations}>
              <div className={s.babysittersFont}>{props.Sitter.babysitter_location}</div>
              <p className={s.babysittersInfoFont}>2 km 15 min</p>
              <button className={s.btnLocation}>see direction</button>
            </div>

            <div className={s.babysittersDemands}>
              {props.Sitter.tags !== undefined
                ? props.Sitter.tags.map((t) => (
                    <span className={s.demands}>{t.name}</span>
                  ))
                : null}
            </div>

            <div className={s.babysittersStatus}>
              {props.store.BabyStatus.map((m) =>
                +props.Sitter.babysitter_status === m.id ? (
                  <span
                    className={`${s.babysittersStatus} ${
                      m.name !== "New" ? s.statusColor : null
                    }`}
                    style={{ background: m.color }}
                  >
                    {m.name}{" "}
                  </span>
                ) : null
              )}
            </div>

            <div className={s.babysittersNotes}>
              {props.Sitter.babysitter_qcomment !== "null"
                ? props.Sitter.babysitter_qcomment
                : null}
            </div>

            <div className={s.babysittersActions}>
              <img src="/img/phone.svg" alt="" />
              <img src="/img/email.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBabysitters;
