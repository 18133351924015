import React from "react";
import s from "./Stage.module.scss";
import FilterBabysitters from "../Filter/Filter";
import b from "../Babysitters/Babysitters.module.scss";

const Stage1 = (props) => {
	const GetAge = (dateString) => {
			let today = new Date();
			let birthDate = new Date( dateString );
			let age = today.getFullYear() - birthDate.getFullYear();

			let m = today.getMonth() - birthDate.getMonth();
			let d = today.getDay() - birthDate.getDay();

			if (m < 0 || ( m === 0 && today.getDate() < birthDate.getDate() )) {
				age--;
			}
			if (age === 0) {
				m = 12 + m;
				if (d < 0 || ( d === 0 && today.getDate() < birthDate.getDate() )) {
					m--;
				}
			}
			return age ? age + " y." + m + " m." : null;
		},
		store = props.store,
		Clients = [],
		Matching = store.Matching.map( (f, index) =>
			Object.keys( f ).length !== 0 ? Clients.push( f ) : null
		),
		copytext = (el) => {
			var tempInput = document.createElement( "input" );
			tempInput.style = "position: absolute; left: -1000px; top: -1000px";
			tempInput.value = el;
			document.body.appendChild( tempInput );
			tempInput.select();
			console.log( tempInput.value );
			document.execCommand( "copy" );
			document.body.removeChild( tempInput );
		},
		regxAddress = (address) => {
			let addressNew = address.replace( /\D/g, "" );
			let addressRegx = addressNew.replace( /(\d)(?=(\d{3})+(\D|$))/g, "$1 " );
			return addressRegx;
		};

	return (
		<>
			{Clients.map( (c, index) => (
				<div className={s.element} key={index}>
					<div className={s.client}>
						<div>
							{store.StartOptions.map( (status, index) =>
								c.client_start === status.id ? (
									<div className={s.startOption} style={{background: status.color}}>
										{status.short_title}
									</div>
								) : null
							)}
						</div>

						<div className={s.name}>
              <span className={s.circle}>
                {c.client_name[ 0 ]}
								{c.client_name.split( " " )[ 1 ][ 0 ]}
              </span>
							<span className={s.targetName}>{c.client_name}</span>
						</div>
						<div className={s.address}>
              <span>
                <img src="/img/location.svg" alt=""/>
              </span>
							<span>
                {c.client_location} {regxAddress( c.client_address )}
              </span>
							<div className={s.email}>
								<img
									src="/img/email.svg"
									alt=""
									onClick={() => copytext( c.client_email )}
								/>
							</div>
							<div className={s.phone}>
								<span onClick={() => copytext( c.client_phone )}>{c.client_phone}</span>
								<img
									src="/img/copy.svg"
									alt=""
									onClick={() => copytext( c.client_phone )}
								/>
							</div>
						</div>
						<div className={s.childrens}>
							{props.store.Children.map( (m, index) =>
								m.client_id === c.client_id ? (
									<div className={s.itemChild} key={index}>
										{c.sex === 1 ? (
											<span>
                                                <img src="/img/men.svg" alt=""/>
                                              </span>
										) : (
											<span>
                                                <img src="/img/girl.svg" alt=""/>
                                              </span>
										)}
										{GetAge( new Date( m.birth ) )}
									</div>
								) : null
							)}

							{store.Animals.map( (a, index) =>
								a.client_id === c.client_id ? (
									<div className={s.itemChild}>
										{a.animal === "Cat" ? (
											<span>
                    <img src="/img/cat.svg" alt=""/>
                  </span>
										) : (
											<span>
                        <img src="/img/dog.svg" alt=""/>
                    </span>
										)}

										{a.count}
									</div>
								) : null
							)}
						</div>

						<div className={s.language}>
							{c.language !== undefined ?
								<div className={s.itemLanguage}>
									{c.language.map( (l, index) => (
										<div className={s.itemLanguageValue} key={index}>
											<div>{l.lang_name}</div>

											{store.Levels.map( (level, index) =>
												l.level_id === level.id ? (
													<div key={index} style={
														level.order !== null
															? {
																background: level.color,
																padding: "2px",
																"margin-left": "5px",
																"margin-right": '5px',
																'display': 'block'
															}
															: {display: 'none'}
													}
													>
														{level.name}
													</div> ) : null )}


											{l.priority !== null
												? l.priority !== 0
													? <div style={{'margin-right': '5px'}}>Pr {l.priority}</div>
													: null
												: null}


											{l.additional_lang !== null ? (
												<div className={s.itemLanguageDefoult}>
													<span>{l.additional_lang}</span>
												</div>
											) : null}
										</div>
									) )}

								</div> : null}
						</div>

						<div className={s.status}>
							{store.Status.map( (status, index) =>
								c.client_status === status.id ? (
									<span
										className={`${
											status.name !== "New" ? s.clientColorStatusWhite : null
										} `}
										style={
											c.status !== 1 ? {background: status.color} : {color: "#000"}
										}
									>
                                        {status.name}
                                      </span>
								) : null
							)}
						</div>
						<div className={s.date}>
							<span>{c.client_added} - rejected</span>
							<span>
                                <img src="/img/time.svg" alt=""/>
                              </span>
						</div>
					</div>
					<div className={s.babysitters}>
						<div className={s.headerBabysitters}>
							<span>All babysitters</span>
						</div>
						{c.Sitter !== undefined
							? c.Sitter.map( (si) => <FilterBabysitters Sitter={si} store={store}/> )
							: null}

						<div className={b.btnConnect}>
							<button className={b.btnConnect}>Connect</button>
						</div>
					</div>
				</div>
			) )}
		</>
	);
};

export default Stage1;
